$(document).ready(function () {

    let bLazy = new Blazy({
        offset: 150,
        selector: '.--lazy-load'
    });

    // const player = new Plyr('#player', {
    //     controls: false,
    //     fullscreen: { enabled: false, fallback: true, iosNative: false },
    //     clickToPlay: false
    // })
})